import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutSignin from './layouts/LayoutSignin';

// Views 
import Home from './views/Home';
import Secondary from './views/Secondary';
import Login from './views/Login';
import Signup from './views/Signup';
import Search from './views/Search';
import SearchMultiple from './views/SearchMultiple';
import SearchApi from './views/SearchApi';
import SearchLicense from './views/SearchLicense';
import SearchTestSearch from './views/SearchTestSearch';
import SearchPublic from './views/SearchPublic';
import Privacy from './views/Privacy';
import Support from './views/Support';
class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/secondary" component={Secondary} layout={LayoutAlternative} />
            <AppRoute exact path="/login" component={Login} layout={LayoutSignin} />
            <AppRoute exact path="/signup" component={Signup} layout={LayoutSignin} />


            <AppRoute exact path="/ruc" component={Search} layout={LayoutAlternative} />
            <AppRoute exact path="/ruc/consulta" component={Search} layout={LayoutAlternative} />

            <AppRoute exact path="/ruc/consulta/simple" component={Search} layout={LayoutAlternative} />
            <AppRoute exact path="/ruc/search/single" component={Search} layout={LayoutAlternative} />

            <AppRoute exact path="/ruc/consulta/multiple" component={SearchMultiple} layout={LayoutAlternative} />
            <AppRoute exact path="/ruc/search/multiple" component={SearchMultiple} layout={LayoutAlternative} />
 
            <AppRoute match path="/ruc/consulta/api/" component={SearchTestSearch} layout={LayoutAlternative} />
            <AppRoute match path="/ruc/consulta/api/:format" component={SearchTestSearch} layout={LayoutAlternative} />
            <AppRoute match path="/ruc/:id([0-9]{1,12})" component={SearchTestSearch} layout={LayoutAlternative} />
            <AppRoute match path="/ruc/:id([0-9]{1,12}-[a-z-]+)" component={SearchPublic} layout={LayoutAlternative} />

            <AppRoute exact path="/ruc" component={Search} layout={LayoutAlternative} />

            <AppRoute exact path="/ruc/license" component={SearchLicense} layout={LayoutAlternative} />

            <AppRoute exact path="/privacy" component={Privacy} layout={LayoutAlternative} />
            <AppRoute exact path="/support" component={Support} layout={LayoutAlternative} />

          </Switch>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);