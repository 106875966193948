import React from "react";

import Button from "../components/elements/Button";
import Input from "../components/elements/Input";
import axios from "axios";
import { Badge, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactJson from "react-json-view";
const licenseKey = "ZjY2ZDQ4M2ZlOGU5NGQ2NWJhY2MxODA2M2RlMWU5ZDg=";

class SearchPublic extends React.Component {
  constructor(props) {
    super(props);

    this.handleChangeInput = this.handleChangeInput.bind(this);
  }
  state = {
    demoModalActive: false,
    input_ruc: "",
    loading: false,
    generated: null,
    format: "normal",
    mapping: [
      { id: "id", label: "RUC" },
      { id: "name", label: "NOMBRE O RAZÓN SOCIAL" },
      { id: "status", label: "ESTADO DEL CONTRIBUYENTE" },
      { id: "condition", label: "CONDICIÓN DE DOMICILIO" },
      { id: "location_code", label: "UBIGEO" },
      { id: "address_type", label: "TIPO DE VÍA" },
      { id: "address_name", label: "NOMBRE DE VÍA" },
      { id: "address_zone_code", label: "CÓDIGO DE ZONA" },
      { id: "address_zone_type", label: "TIPO DE ZONA" },
      { id: "address_number", label: "NÚMERO" },
      { id: "address_interior", label: "INTERIOR" },
      { id: "address_building", label: "LOTE" },
      { id: "address_apartment", label: "DEPARTAMENTO" },
      { id: "address_block", label: "MANZANA" },
      { id: "address_distance", label: "KILÓMETRO" },
      { id: "updated_on", label: "ACTUALIZADO" },
    ],
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  };

  btn_generateKey = (id_) => {
    // if (isNaN(id_)) {
    //   return;
    // }

    this.setState({ loading: true });

    // setTimeout(() => {
    let config = {
      headers: {
        "Ocp-Apim-Subscription-Key": atob(licenseKey),
      },
    };

    let endpoint =
      "https://app.api.activadata.io/ruc/v1/RucCustomSearch?code=-T0mtqSkfyiNZmCw6Gb67RUWAQsg6tZgzo5QSrcxRpY-AzFuOzSzcg==&";

    let payload = {
      entity: id_,
    };

    axios
      .post(endpoint, payload, config)
      .then((response) => {
        this.setState({ generated: response.data, loading: false });

        document.title = `${response?.data?.id} - ${response?.data?.name} | Consulta RUC | Activa Data`;
      })
      .catch((err) => {
        alert("Error al buscar. Verifique su llave/key.");

        this.setState({ loading: false });
      });
    // }, 500);
  };

  handleChangeInput = (event) => {
    this.setState({ input_ruc: event.target.value });
  };

  componentDidMount = (e) => {
    let query = this.props.match.params.id;
    let format = this.props.match.params.format;

    this.setState({ input_ruc: query, format: format });

    if (query) {
      this.btn_generateKey(query);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container" style={{ paddingTop: "45px" }}>
          <div>
            <h1>{this.state?.generated && this.state?.generated["name"]}</h1>
            {/* <div className="tiles-wrap"> */}
            {/* <div className="tiles-item"> */}
            {/* <h4>Buscar RUC</h4> */}

            {/* <div className="tiles-item-inner"> */}
            {/* <form action="javascript:void(0)"> */}
            {/* <fieldset>
                    <div className="mb-12">
                      <Input
                        label="Ruc"
                        placeholder="Ingrese RUC"
                        labelHidden
                        required
                        autoComplete="on"
                        value={this.state.input_ruc || ""}
                        onChange={this.handleChangeInput}
                        disabled={this.state.loading}
                      />
                    </div>

                    <div className="mt-24 mb-32">
                      <Button
                        color="primary"
                        wide
                        onClick={() =>
                          this.btn_generateKey(this.state.input_ruc)
                        }
                        disabled={
                          this.state.loading || isNaN(this.state.input_ruc)
                        }
                      >
                        {this.state.loading === true && (
                          <span>
                            Loading...{" "}
                            <Spinner animation="border" role="status" size="sm">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>{" "}
                          </span>
                        )}

                        {this.state.loading !== true && <span>Buscar</span>}
                      </Button>
                    </div>
                  </fieldset> */}

            {/* </form> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
          </div>

          {this.state?.generated && (
            <div className="row" style={{ fontSize: ".8em" }}>
              <div className="col-md-8">
                <table>
                  <tbody>
                    {this.state.mapping.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <strong>{item.label}</strong>
                          </td>
                          <td>{this.state.generated[item.id]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="col-md-4">
                <hr />
                <div className="row mb-12">
                  <div className="col-md-8">
                    <strong>Modo programador</strong>
                  </div>
                  <div className="col-md-4">
                    <Link
                      to="/ruc/license/"
                      className=" button button-dark button-wide-mobile button-sm"
                    >
                      Obtener Key API
                    </Link>
                  </div>

                  <div className="clearfix"></div>
                </div>

                <ReactJson
                  src={this.state?.generated}
                  name={null}
                  iconStyle={"circle"}
                  displayDataTypes={false}
                />
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default SearchPublic;
