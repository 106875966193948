import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroFull from '../components/sections/HeroFull';
import Team from '../components/sections/Team';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class Privacy extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    const genericSection01Header = {
      title: 'Buttons - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection02Header = {
      title: 'Input forms - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection03Header = {
      title: 'Modal - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection04Header = {
      title: 'FAQ - Lorem ipsum is placeholder text commonly used.'
    }

    return (
      <React.Fragment>


        <div className='clearfix' style={{ paddingTop: '50px' }} >

<div style={{ paddingTop: '50px' }}>
Privacy Policy for Consulta RUC (EntyTax)

<br/>

Effective Date: July 1, 2023
<br/>
<br/>

Thank you for using Consulta RUC ("App"), which is owned and operated by Open Activa ("we," "us," or "our"). This Privacy Policy outlines the information we collect, how we use, share, and protect it when you use our App. By accessing and using our App, you agree to the terms of this Privacy Policy.
<br/>
<br/>
1.	Information We Collect: We may collect the following types of information when you use our App: a. Personal Information: This includes information that can be used to identify you, such as your name, email address, phone number, and other contact details. b. Non-Personal Information: This includes anonymous data that does not directly identify you, such as App usage statistics, device information (e.g., device type, operating system, IP address), and analytics data.

<br/>2.	How We Use Your Information: We may use the information we collect for the following purposes: a. Provide and Improve the App: To operate, maintain, and enhance the functionality of the App and to develop new features. b. Communication: To respond to your inquiries, provide customer support, and send you important updates or promotional materials related to the App (unless you opt-out). c. Analytics: To understand how users interact with the App, analyze usage patterns, and improve our services. d. Legal Compliance: To comply with applicable laws, regulations, and legal processes.
<br/>3.	Data Sharing: We do not sell, trade, or rent your personal information to third parties. However, we may share your information with the following entities for specific purposes: a. Service Providers: We may share your information with trusted third-party service providers who assist us in providing the App's functionalities (e.g., hosting services, customer support). b. Legal Requirements: We may disclose your information if required by law or to protect our rights, safety, or property, or the rights, safety, or property of others. c. Business Transfers: If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.
<br/>4.	Security: We implement reasonable security measures to protect your information from unauthorized access, loss, misuse, or alteration. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
<br/>5.	Data Retention: We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
<br/>6.	Children's Privacy: Our App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under this age. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will take steps to delete such information.
<br/>7.	Your Choices: You can access, correct, or delete your personal information by contacting us at contact@consultaruc.pe. You may also have the option to opt-out of receiving promotional communications from us.
<br/>8.	Third-Party Links: Our App may contain links to third-party websites or services that are not under our control. We are not responsible for the privacy practices of these third-party entities, and we encourage you to review their respective privacy policies.
<br/>9.	Changes to Privacy Policy: We reserve the right to modify this Privacy Policy at any time. If we make material changes, we will notify you through the App or other means, and the updated policy will become effective upon posting.
<br/>10.	Contact Us: If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at contact@consultaruc.pe.
<br/>
<br/>By using our App, you acknowledge that you have read and understood this Privacy Policy and agree to be bound by its terms. Thank you for trusting Consulta RUC and Open Activa.
<br/><br/>

Open Activa
<br/>
Lima, Peru
<br/>
contact@consultaruc.pe



</div>
        </div>


        {/* <Cta topDivider bottomDivider split className="reveal-from-top" /> */}
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

export default Privacy;