import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/elements/Button";
import Input from "../components/elements/Input";
import axios from "axios";
import { set } from "lodash";
const licenseKey = "ZjY2ZDQ4M2ZlOGU5NGQ2NWJhY2MxODA2M2RlMWU5ZDg=";
class SearchLicense extends React.Component {
  state = {
    demoModalActive: false,

    input_name: "",
    input_lastname: "",
    input_email: "",
    loading: false,
    is_valid_email: false,
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  };

  btn_generateKey = () => {
    let endpoint =
      "https://app.api.activadata.io/ruc/v1/RegisterUser?code=-T0mtqSkfyiNZmCw6Gb67RUWAQsg6tZgzo5QSrcxRpY-AzFuOzSzcg==";

    let data = {
      name: this.state.input_name,
      lastName: this.state.input_lastname,
      email: this.state.input_email,
      generatekey: true,
    };

    this.setState({ loading: true });

    let config = {
      headers: {
        "Ocp-Apim-Subscription-Key": atob(licenseKey),
      }
    };

    

    axios
      .post(endpoint, data, config)
      .then((response) => {
        this.setState({ generated: response.data, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        alert("Error al generar la llave");
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="container" style={{ paddingTop: "45px" }}>
          <div>
            <div className="tiles-wrap">
              <div className="tiles-item">
                <h4>Registro simple y reciba su key de inmediato</h4>
                <small>Olvidó su KEY? Puede usar este formulario para volver a solicitarlo.</small>

                <div className="tiles-item-inner">
                  <form validate="required" autoComplete="off">
                    <fieldset>
                      <div className="mb-12">
                        <Input
                          label="Name"
                          placeholder="Nombre"
                          labelHidden
                          required
                          onChange={(e) =>
                            this.setState({ input_name: e.target.value })
                          }
                        />
                      </div>

                      <div className="mb-12">
                        <Input
                          label="LastName"
                          placeholder="Apellido"
                          labelHidden
                          required
                          onChange={(e) =>
                            this.setState({ input_lastname: e.target.value })
                          }
                        />
                      </div>

                      <div className="mb-12">
                        <Input
                          type="email"
                          label="Email"
                          placeholder="Email"
                          labelHidden
                          required
                          onChange={(e) => {
                            let rexEx =
                              /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;

                            let isValidEmail = rexEx.test(e.target.value);

                            this.setState({
                              input_email: e.target.value,
                              is_valid_email: isValidEmail,
                            });
                          }}
                        />
                      </div>

                      <div className="mt-24 mb-32">
                        <Button
                          color="primary"
                          wide
                          onClick={this.btn_generateKey}
                          disabled={
                            this.state.loading === true ||
                            !(
                              this.state.loading !== true &&
                              this.state.input_email &&
                              this.state.input_name &&
                              this.state.input_lastname &&
                              this.state.is_valid_email
                            )
                          }
                        >
                          Generar API Key
                        </Button>

                        {this.state.loading === true && (
                          <div className="text-center">
                            <div
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                            <span className="sr-only"> Loading... </span>
                          </div>
                        )}
                      </div>

                      {this.state?.generated && (
                        <div className="mb-12" style={{ fontSize: ".8em" }}>
                          <table>
                            <tbody>
                              <tr>
                                <td >
                                  <strong>Licencia emitida para</strong>
                                </td>
                              </tr>

                              <tr>
                                <td >{this.state.input_email}</td>
                              </tr>

                              <tr>
                                <td >
                                  <strong>Client ID</strong>
                                </td>
                              </tr>

                              <tr>
                                <td >Enviado a su correo.</td>
                              </tr>

                              <tr>
                                <td >
                                  <strong>Client Secret</strong>
                                </td>
                              </tr>
                              <tr>
                                <td >Enviado a su correo.</td>
                              </tr>
                              <tr>
                                <td >
                                  <strong>API Endpoint</strong>
                                </td>
                              </tr>

                              <tr>
                                <td >
                                  <span style={{ fontFamily: "Courier" }}>
                                    {this.state?.generated?.api_url}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td >
                                  {this.state?.generated?.email_sent && (
                                    <strong>
                                      No se preocupe, hemos enviado estos datos
                                      a su correo.
                                    </strong>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchLicense;
