import React from 'react';
import ReactDOM from 'react-dom'
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroFull from '../components/sections/HeroFull';
import Team from '../components/sections/Team';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';
import IframeResizer from 'iframe-resizer-react';

class SearchMultiple extends React.Component {

  state = {
    demoModalActive: false,
    iFrameHeight: '0px'
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    const genericSection01Header = {
      title: 'Buttons - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection02Header = {
      title: 'Input forms - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection03Header = {
      title: 'Modal - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection04Header = {
      title: 'FAQ - Lorem ipsum is placeholder text commonly used.'
    }

    return (
      <React.Fragment>


        <div className='clearfix if-main-content' style={{ paddingTop: '80px' }} >


          <div style={{ textAlign: 'center' }}>
            <small>
              <a href='https://e-consultaruc.sunat.gob.pe/cl-ti-itmrconsmulruc/jrmS00Alias' target='_blank'>
                 <strong>Sunat</strong> https://e-consultaruc.sunat.gob.pe/cl-ti-itmrconsmulruc/jrmS00Alias
              </a>
            </small>
          </div>


          <iframe

            src='https://e-consultaruc.sunat.gob.pe/cl-ti-itmrconsmulruc/jrmS00Alias'
            frameBorder="0"
            width='100%'
            height='750px'
          >
          </iframe>

          {/* <IframeResizer 

            log
            src="https://e-consultaruc.sunat.gob.pe/cl-ti-itmrconsmulruc/jrmS00Alias"
            style={{ width: '1px', minWidth: '100%'}}


          /> */}

          {/* <iframe 
              id="x452343"
                src='https://e-consultaruc.sunat.gob.pe/cl-ti-itmrconsmulruc/jrmS00Alias'
                frameBorder="0"
                scrolling="no" 
                ref="iframe" 
                style={{maxWidth:640, width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}
                width='100%'
                height={this.state.iFrameHeight} 
                onLoad={() => {
                  const obj = ReactDOM.findDOMNode(this);

                  console.log(obj);

                  this.setState({
                      "iFrameHeight":  obj.contentWindow.document.body.scrollHeight + 'px'
                  });
                }} 
                
                >
            </iframe> */}
        </div>



        {/* <Cta topDivider bottomDivider split className="reveal-from-top" /> */}
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

export default SearchMultiple;